// phrp_r4 공통 코드(tbl_code)
const COMMON_GROUP_CODE = {
  CODE_SRVC_STTUS: '1', // 서비스 승인상태 코드
  CODE_GRANT_TYPE: '5', // OAuth 인증 타입 코드
  CODE_CONFM_STTUS: '2', // 환경 승인상태 코드
  CODE_SYSSET_TYPE: '7', // 시스템 설정 타입 코드
  CODE_RULE_HEALTH_CODE: '9', // 헬스코드(알람 조건 관리)
  CODE_SUBSCRIPTION_HEALTH_CODE: '10', // 헬스코드(구독 관리)
};

// phrp_market 공통 코드
const MARKET_COMMON_GROUP_CODE = {
  GOODS_CN_TYPE_CODE: 'GOODS_CN_TYPE_CODE', // 상품 내용 유형 코드
};

// phrp_market 배너 공통 코드
const MARKET_COMMON_BANNER_TYPE_CODE = 'BANNER_TYPE_CODE';

// 알람 범위 목록
const RULE_SCOPE_LIST = [
  {
    value: '==',
    name: '==',
  },
  {
    value: '>',
    name: '>',
  },
  {
    value: '>=',
    name: '>=',
  },
  {
    value: '<=',
    name: '<=',
  },
  {
    value: '<',
    name: '<',
  },
];

const SUBSCRIPTION_ITEM_LIST = [
  {
    value: 'code',
    name: 'code',
  },
];

const ANNYMTY_THREAD_RESULT = {
  FINISH: 'FINISH',
  PROGRESS: 'PROGRESS',
};

export {
  COMMON_GROUP_CODE,
  MARKET_COMMON_GROUP_CODE,
  MARKET_COMMON_BANNER_TYPE_CODE,
  RULE_SCOPE_LIST,
  SUBSCRIPTION_ITEM_LIST,
  ANNYMTY_THREAD_RESULT,
};
