import { operation } from './index';
import Axios from 'axios';
import store from '@/store/index';

/**
 * 비밀번호 확인
 * @returns
 */
function checkPassword(payload) {
  return operation.post('/operator/password_check', payload);
}

/**
 * 관리자 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getOperatorList(reqObj) {
  return operation.post('/operator/list', reqObj);
}
/**
 * 관리자 서비스 리스트 조회
 * @param {*} reqObj
 * @returns
 */
function getOperatorSrvcList(reqObj) {
  return operation.get('/operator/srvcList', reqObj);
}
/**
 * 관리자 상세정보 조회
 * @param {string} userId
 * @returns
 */
function getOperatorDetail(userId) {
  return operation.post('/operator/detail', userId);
}
/**
 * 관리자 ID 중복조회
 * @param {string} userId
 * @returns
 */
function chkOperDuplication(userId) {
  return operation.post('/operator/duplicate', userId);
}

/**
 * 관리자 등록
 * @param {string} reqObj
 * @returns
 */
function insertOperator(reqObj) {
  return operation.post('/operator', reqObj);
}
/**
 * 트랜잭션이력 리스트 조회
 * @param {*} reqObj
 * @returns
 */
function getTrnsclogList(reqObj) {
  return operation.post('/transactionlog/list', reqObj);
}
/**
 * 트랜잭션이력 상세정보 조회
 * @param {*} trnscSn
 * @returns
 */
function getTrnsclogDetail(trnscSn) {
  return operation.post('/transactionlog/detail', trnscSn);
}
/**
 * 트랜잭션이력 삭제
 * @param {*} trnscSn
 * @returns
 */
function deleteTrnsclog(trnscSn) {
  return operation.post('/transactionlog/delete', trnscSn);
}
/**
 * 선택한 트랜잭션이력 전부 삭제
 * @param {*} trnscSns
 * @returns
 */
function deleteAllTrnsclog(trnscSns) {
  return operation.post('/transactionlog/deleteAll', trnscSns);
}
/**
 * 관리자 비밀번호 변경
 * @param {*} reqObj
 * @returns
 */
function updateOperatorPw(reqObj) {
  return operation.post('/operator/password_change', reqObj);
}

/**
 * 관리자 ID 잠금 설정
 * @param {*} reqObj
 * @returns
 */
function updateLockOperator(reqObj) {
  return operation.post('/operator/use', reqObj);
}

/**
 * 관리자 정보 수정
 * @param {*} reqObj
 * @returns
 */
function updateOperator(reqObj) {
  return operation.post('/operator/update', reqObj);
}

/**
 * 관리자 그룹 목록 조회
 * @returns
 */
function getAuthorities() {
  return operation.get('/operator/groups');
}

/**
 * 관리자 삭제
 * @param {string} userId
 * @returns
 */
function delOperator(userId) {
  return operation.post('/operator/delete', userId);
}

/**
 * 관리자 IP 수정
 * @param {*} reqObj
 * @returns
 */
function updateOperAllowIp(reqObj) {
  return operation.post('/operator/validIp/update', reqObj);
}

/**
 * 관리자 그룹 목록 리스트 조회
 * @param {*} reqObj
 * @returns
 */
function getAuthoritiesList(reqObj) {
  return operation.post('/group/list', reqObj);
}

/**
 * 관리자 권한 등록 중복검사 (Id/Name)
 * @param {*} reqObj
 * @returns
 */
function operAllowDupliCheck(reqObj) {
  return operation.post('group/duplicate', reqObj);
}

/**
 * 관리자 권한 등록
 * @param {*} reqObj
 * @returns
 */
function insertOperAllow(reqObj) {
  return operation.post('group/insert', reqObj);
}

/**
 * 관리자 권한 삭제
 * @param {*} reqObj
 * @returns
 */
function deleteOperAllow(reqObj) {
  return operation.post('group/delete', reqObj);
}

/**
 * 관리자 권한 상세보기
 * @param {string} groupId
 * @returns
 */
function detailOperAllow(groupId) {
  return operation.post('group/detail', groupId);
}

/**
 * 관리자 권한 수정
 * @param {reqObj} reqObj
 * @returns
 */
function updateOperAllow(reqObj) {
  return operation.post('group/update', reqObj);
}

/**
 * 관리자 권한 메뉴
 * @param {reqObj} reqObj
 * @returns
 */
function updateOperAllowMenu(reqObj) {
  return operation.post('group/update/menu', reqObj);
}

/**
 * 질의응답관리 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getQnaList(reqObj) {
  return operation.post('/qna/list', reqObj);
}

/**
 * 질의응답관리 상세정보 조회
 * @param {*} reqObj
 * @returns
 */
function getQnaDetail(questNo) {
  return operation.post('/qna/detail', { questNo });
}

/**
 * 질의응답관리 답변/수정
 * @param {*} reqObj
 * @returns
 */
function updateQna(reqObj) {
  return operation.post('/qna/update', reqObj);
}

/**
 * 질의응답관리 삭제
 * @param {*} questNo
 * @returns
 */
function deleteQna(questNo) {
  return operation.post('/qna/delete', { questNo });
}

/**
 * 공지사항 리스트  조회
 * @param {*} reqObj
 * @returns
 */
function getNoticeList(reqObj) {
  return operation.post('/notice/list', reqObj);
}

/**
 * 공지사항 상세정보 조회
 * @param {String} noticeNo
 * @returns
 */
function getNoticeDetail(noticeNo) {
  return operation.post('/notice/detail', noticeNo);
}

/**
 * 공지사항 등록
 * @param {*} payload
 * @returns
 */
async function insertNotice(payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });

  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await Axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/operation/notice`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response;
  }
}

/**
 * 공지사항 수정
 * @param {*} payload
 * @returns
 */
async function updateNotice(payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });

  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await Axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/operation/notice/update`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response;
  }
}

/**
 * 공지사항 삭제
 * @param {String} noticeNo
 * @returns
 */
function delNotice(noticeNo) {
  return operation.post('/notice/delete', noticeNo);
}

/**
 * 시스템 설정 관리 목록 리스트 조회
 * @param {*} reqObj
 * @returns
 */
function getSystemList(reqObj) {
  return operation.post('/system/list', reqObj);
}
/**
 * 시스템 설정 관리 파일 조회
 * @param {*} fileSn
 * @returns
 */
function getFileDetail(fileSn) {
  return operation.post('/system/detail/file', fileSn);
}
/**
 * 시스템 설정 등록
 * @param {*} payload
 * @returns
 */
async function addSysSet(payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });
  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await Axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/operation/system/insert`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response;
  }
}
/**
 * 시스템 설정 수정 첨부파일 삭제
 * @param {*} reqObj
 * @returns
 */
function deleteFile(reqObj) {
  return operation.post('/system/fileDelete', reqObj);
}
/**
 * 시스템 설정 수정
 * @param {*} payload
 * @returns
 */
async function modifySysSet(payload) {
  const formData = new FormData();

  const keys = Object.keys(payload);
  keys.forEach(key => {
    formData.append(key, payload[key]);
  });
  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: `Bearer ${store.state.auth.token}`,
      },
    };
    return await Axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/operation/system/update`,
      formData,
      settingHeaders,
    );
  } catch (error) {
    return error.response;
  }
}
/**
 * 시스템 설정 삭제
 * @param {*} reqObj
 * @returns
 */
function deleteSysSet(reqObj) {
  return operation.post('/system/delete', reqObj);
}
/**
 * 경진대회 리스트 조회
 * @param {*} reqObj
 * @returns
 */
function getExhibitionList(reqObj) {
  return operation.post('/exhibition/list', reqObj);
}
/**
 * 경진대회 상세정보 조회
 * @param {*} userId
 * @returns
 */
function getExhibitionDetail(userId) {
  return operation.post('/exhibition/detail', userId);
}
export {
  checkPassword,
  getOperatorList,
  getOperatorSrvcList,
  getOperatorDetail,
  chkOperDuplication,
  insertOperator,
  updateOperatorPw,
  updateLockOperator,
  updateOperator,
  getAuthorities,
  getAuthoritiesList,
  delOperator,
  getTrnsclogList,
  getTrnsclogDetail,
  deleteTrnsclog,
  deleteAllTrnsclog,
  updateOperAllowIp,
  operAllowDupliCheck,
  insertOperAllow,
  deleteOperAllow,
  detailOperAllow,
  updateOperAllow,
  updateOperAllowMenu,
  getQnaList,
  getQnaDetail,
  updateQna,
  deleteQna,
  getSystemList,
  getFileDetail,
  addSysSet,
  deleteFile,
  modifySysSet,
  deleteSysSet,
  getExhibitionList,
  getExhibitionDetail,
  getNoticeList,
  getNoticeDetail,
  insertNotice,
  updateNotice,
  delNotice,
};
