import Vue from 'vue';
import VueI18n from 'vue-i18n';
import en from './en';
import ko from './ko';

Vue.use(VueI18n);

// global 버전 아닌 경우 'ko'로 설정
if (`${process.env.VUE_APP_GLOBAL}` === 'false')
  localStorage.setItem('lang', 'ko');

export default new VueI18n({
  locale: localStorage.getItem('lang') || 'ko',
  fallbackLocale: 'ko',
  messages: { ko, en },
});
