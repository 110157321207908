import Vue from 'vue';
import store from '@/store/index';
import router from '@/routes/index.js';
import i18n from '@/i18n/index';
/**
 * 오류 공통처리
 * @param {Error} error
 */
export function handleError(error) {
  console.log('handleError:::', error, error.response); // 로그인 페이지 오류
  if (!error.response && !error.message) {
    Vue.dialogs.alert(i18n.t('common.alert.error-server'));
  }
  // 401
  else if (error.response && error.response.status == 401) {
    Vue.dialogs.alert(i18n.t('common.alert.error-session'));
    store.commit('auth/CLEAR_SESSION');
    router.push('/login');
  }
  // 404
  else if (error.response && error.response.status == 404) {
    Vue.dialogs.alert(`${error.response.data.message}`);
  } else {
    const status = error.response.status;
    Vue.dialogs.alert(i18n.t('common.alert.error-etc', { status }));
    return;
  }
}
