import Vue from 'vue';
import App from './App.vue';
import router from '@/routes/index';
import store from '@/store/index';
import VCalendar from 'v-calendar';
import Dialogs from './components/Dialogs.vue';
import Highcharts from 'highcharts';
import highchartsMore from 'highcharts/highcharts-more';
import HighchartsVue from 'highcharts-vue';
import NoDataModule from 'highcharts/modules/no-data-to-display.js';
import vuetify from './plugins/vuetify';
import './plugins/vee-validate';
import VueHtmlToPaper from 'vue-html-to-paper';
import i18n from '@/i18n/index';

const options = {
  name: '_blank',
  specs: ['fullscreen=yes', 'titlebar=yes', 'scrollbars=yes'],
  styles: [
    'https://maxcdn.bootstrapcdn.com/bootstrap/4.0.0/css/bootstrap.min.css',
    'https://unpkg.com/kidlat-css/css/kidlat.css',
  ],
};
Vue.use(VueHtmlToPaper, options);

Vue.config.productionTip = false;

NoDataModule(Highcharts);
highchartsMore(Highcharts);

// dialog
Dialogs.install = (Vue, options) => {
  Vue.prototype.$dialogs = new (Vue.extend(Dialogs))({ propsData: options });
  Vue.dialogs = Vue.prototype.$dialogs;
};
Vue.prototype.$store = store;

Vue.use(Dialogs);
Vue.use(HighchartsVue);
Vue.use(VCalendar, {
  componentPrefix: 'vc',
});

// Set global options
Highcharts.setOptions({
  lang: {
    noData: i18n.t('dashboard.chart.noData'),
    thousandsSep: ',',
  },
});

new Vue({
  render: h => h(App),
  router,
  vuetify,
  store,
  i18n,
}).$mount('#app');
