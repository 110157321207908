/*eslint-disable*/
import Vue from 'vue';
import VueRouter from 'vue-router';
import store from '@/store/index';
import introRouter from './introRouter';
import dashboardRouter from './dashboardRouter';
import supportRouter from './supportRouter';
import sampleRouter from './sampleRouter';
import serviceRouter from './serviceRouter';
import phrRouter from './phrRouter';
import operationRouter from './operationRouter';
import marketRouter from './marketRouter';
import i18n from '@/i18n/index';

const otherRoutes = [
  {
    path: '/',
    redirect: '/login',
  },
];

// const pat

let allRoutes = [];
allRoutes = allRoutes.concat(
  otherRoutes,
  introRouter,
  dashboardRouter,
  supportRouter,
  sampleRouter,
  serviceRouter,
  phrRouter,
  operationRouter,
  marketRouter
);

Vue.use(VueRouter);

const routes = allRoutes;

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

router.beforeEach(async (to, from, next) => {
  store.commit('SET_PATH_NAME', to.path);
  if (to.meta?.anonymous) { 
    next();
  } else { // 로그인 화면이 아니라면 세션 확인
    await store.dispatch('auth/FETCH_SESSION').then(({status, data})=> {
      if (status == 200) {
        if (!data.valid) {
          store.commit('auth/CLEAR_SESSION');
          Vue.dialogs.alert(data.message).then(()=> {
            next('/login'); 
          });
        } else {
          store.commit('auth/SET_SESSION', data);
          next();
        }
      } else if (status == 401) {
        Vue.dialogs.alert(i18n.t('common.alert.error-session')).then(()=> {
          next('/login'); 
        });
      } else {
        if (data.message) {
          Vue.dialogs.alert(data.message);
        } else {
          Vue.dialogs.alert(i18n.t('common.alert.error-etc', {status}));
        }
        next();
      }
      
    });
  }
});

export default router;
