import Vue from 'vue';
import { extend, ValidationObserver, ValidationProvider } from 'vee-validate';
import {
  max,
  min,
  required,
  regex,
  email,
  confirmed,
  ext,
  image,
  numeric,
  digits,
} from 'vee-validate/dist/rules';
import CommonMixins from '@/mixins/CommonMixins';
import i18n from '@/i18n/index';

extend('required', {
  ...required,
  message: field => {
    field = CommonMixins.methods.getPostWord(field, '을', '를');
    return i18n.t('common.validate.required', { field });
  },
});
extend('max', {
  ...max,
  message: (_, values) => {
    return i18n.t('common.validate.max', values);
  },
});
extend('min', {
  ...min,
  message: (_, values) => {
    return i18n.t('common.validate.min', values);
  },
});
extend('regex', {
  ...regex,
});
extend('email', {
  ...email,
  message: () => {
    return i18n.t('common.validate.email');
  },
});
// confirmed: 'validateProvider의 name(label)'
extend('confirmed', {
  ...confirmed,
  message: () => {
    return i18n.t('common.validate.confirmed');
  },
});
// 확장자 제한 ex) ext:bmp,jpeg,jpg,png,gif
extend('ext', {
  ...ext,
  message: () => {
    return i18n.t('common.validate.image');
  },
});
// 첨부파일 이미지 파일 제한
extend('image', {
  ...image,
  message: () => {
    return i18n.t('common.validate.image');
  },
});
extend('numeric', {
  ...numeric,
  message: () => {
    return i18n.t('common.validate.numeric');
  },
});
extend('digits', {
  ...digits,
  message: (_, values) => {
    return i18n.t('common.validate.digit', values);
  },
});

extend('min_value', {
  validate(value, { min_value }) {
    let reValue;
    let message;
    if (min_value) {
      if (typeof min_value === 'object' && min_value instanceof Date) {
        reValue = new Date(value);
        message = i18n.t('common.validate.min-date', {
          date: CommonMixins.methods.formatDate(
            min_value,
            'yyyy/MM/dd HH:mm:00',
          ),
        });
      } else {
        reValue = value;
        message = i18n.t('common.validate.min-date', { date: min_value });
      }
      if (reValue >= min_value) {
        return true;
      } else {
        return message;
      }
    } else {
      return true;
    }
  },
  params: ['min_value'],
});

extend('max_value', {
  validate(value, { max_value }) {
    let reValue;
    let message;
    if (max_value) {
      if (typeof max_value === 'object' && max_value instanceof Date) {
        reValue = new Date(value);
        message = i18n.t('common.validate.max-date', {
          date: CommonMixins.methods.formatDate(
            max_value,
            'yyyy/MM/dd HH:mm:00',
          ),
        });
      } else {
        reValue = value;
        message = i18n.t('common.validate.max-date', { date: max_value });
      }
      if (reValue <= max_value) {
        return true;
      } else {
        return message;
      }
    } else {
      return true;
    }
  },
  params: ['max_value'],
});

extend('decimal', {
  validate: (value, { decimals = '*', separator = '.' } = {}) => {
    if (value === null || value === undefined || value === '') {
      return {
        valid: false,
      };
    }
    if (Number(decimals) === 0) {
      return {
        valid: /^-?\d*$/.test(value),
      };
    }
    const regexPart = decimals === '*' ? '+' : `{1,${decimals}}`;
    const regex = new RegExp(
      `^[-+]?\\d*(\\${separator}\\d${regexPart})?([eE]{1}[-]?\\d+)?$`,
    );

    return {
      valid: regex.test(value),
    };
  },
  message: () => {
    return i18n.t('common.validate.numeric');
  },
});

Vue.component('ValidationObserver', ValidationObserver);
Vue.component('ValidationProvider', ValidationProvider);
