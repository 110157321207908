const routes = [
  {
    path: '/service',
    component: () =>
      import(/* webpackChunkName: "service" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'management',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/service/management/ManagementPage.vue'
          ),
      },
      {
        path: 'user',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/service/UserPage.vue'
          ),
      },
      {
        path: 'history',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/service/HistoryPage.vue'
          ),
      },
      {
        path: 'app',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/service/AppPage.vue'
          ),
      },
      {
        path: 'rule',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/service/rule/RulePage.vue'
          ),
      },
      {
        path: 'subscription',
        component: () =>
          import(
            /* webpackChunkName: "service" */ '@/views/service/subscription/SubscriptionPage.vue'
          ),
      },
    ],
  },
];

export default routes;
