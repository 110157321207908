import common from '@/locales/en/common.json';
import dashboard from '@/locales/en/dashboard.json';
import service from '@/locales/en/service.json';
import support from '@/locales/en/support.json';
import phr from '@/locales/en/phr.json';
import operation from '@/locales/en/operation.json';
import login from '@/locales/en/login.json';

const en = Object.assign({
  common,
  dashboard,
  service,
  support,
  phr,
  operation,
  login,
});

export default en;
