const routes = [
  {
    path: '/support',
    component: () =>
      import(/* webpackChunkName: "support" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'transaction',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/views/support/TransactionPage.vue'
          ),
      },
      {
        path: 'prediction',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/views/support/PredictionPage.vue'
          ),
      },
      {
        path: 'environment',
        component: () =>
          import(
            /* webpackChunkName: "support" */ '@/views/support/EnvironmentPage.vue'
          ),
      },
    ],
  },
];

export default routes;
