import {
  getStoreStatus,
  getGoodsStatus,
  getUpperGoodsCtgryCode,
  getCtgryList,
  getMonthApproveStatus,
} from '@/api/marketApi';

export default {
  namespaced: true,
  state: {
    ctgryList: [],
    storeStatus: {}, // 입점 현황
    goodsStatus: {}, // 컨텐츠 현황
    upperGoodsCtgryCode: [], // 컨텐츠 상위 카테고리
    approveStatus: {}, // 입점 승인 현황
  },
  actions: {
    // 카테고리 목록 조회
    async FETCH_CTGRY_LIST(context) {
      const response = await getCtgryList();
      context.commit('SET_CTGRY_LIST', response.data);
      return response;
    },
    // 입점 현황
    async FETCH_STORE_STATUS(context) {
      const response = await getStoreStatus();
      context.commit('SET_STORE_STATUS', response.data);
      return response;
    },
    // 컨텐츠 현황
    async FETCH_GOODS_STATUS(context) {
      const response = await getGoodsStatus();
      context.commit('SET_GOODS_STATUS', response.data);
      return response;
    },
    // 컨텐츠 상위 카테고리 조회
    async FETCH_UPPER_GOODS_CTGRY_CODE(context) {
      const response = await getUpperGoodsCtgryCode();
      context.commit('SET_UPPER_GOODS_CTGRY_CODE', response.data);
      return response;
    },
    // 월별 입점 승인 현황
    async FETCH_MONTH_APPROVE_STATUS(context) {
      const statusPageNo = 0; // 최초 로드 시 (현재 월)
      const response = await getMonthApproveStatus(statusPageNo);
      context.commit('SET_MONTH_APPROVE_STATUS', response.data);
      return response;
    },
  },
  mutations: {
    SET_CTGRY_LIST(state, data) {
      state.ctgryList = data;
    },
    SET_STORE_STATUS(state, data) {
      state.storeStatus = data;
    },
    SET_GOODS_STATUS(state, data) {
      state.goodsStatus = data;
    },
    SET_UPPER_GOODS_CTGRY_CODE(state, data) {
      let dataList = [];
      data.forEach(({ ctgryNm, goodsCtgryCode }) => {
        dataList.push({
          ctgryNm,
          goodsCtgryCode,
        });
      });
      state.upperGoodsCtgryCode = dataList;
    },
    SET_MONTH_APPROVE_STATUS(state, data) {
      state.approveStatus = data;
    },
  },
};
