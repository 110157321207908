import store from '@/store/index';
import { handleError } from './common.js';
// import router from '@/routes/index.js';
// import Vue from 'vue';
import i18n from '@/i18n/index';

function setInterceptors(instance) {
  // Add a request interceptor
  instance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
      };
      if (config.params == null) {
        config.params = {};
      }
      config.params['lang'] = i18n.locale;
      return config;
    },
    function(error) {
      // Do something with request error
      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      return Promise.reject(error);
    },
  );
  return instance;
}

function setAuthInterceptors(instance) {
  // Add a request interceptor
  instance.interceptors.request.use(
    function(config) {
      // Do something before request is sent
      // console.log(config);
      config.headers = {
        'Content-Type': 'application/json;charset=utf-8',
        Authorization: `Bearer ${store.state.auth.token}`,
      };
      if (config.params == null) {
        config.params = {};
      }
      console.log();
      config.params['lang'] = i18n.locale;
      return config;
    },
    function(error) {
      // Do something with request error

      return Promise.reject(error);
    },
  );

  // Add a response interceptor
  instance.interceptors.response.use(
    function(response) {
      // Any status code that lie within the range of 2xx cause this function to trigger
      // Do something with response data
      return response;
    },
    function(error) {
      // Any status codes that falls outside the range of 2xx cause this function to trigger
      // Do something with response error
      handleError(error);

      // handleError와 중복 처리되어 세션 만료 alert이 여러번 뜨는 현상 방지 위해 주석처리
      // if (
      //   error.response?.config?.url != '/logout' &&
      //   error.response?.status == 401
      // ) {
      //   Vue.dialogs.alert(
      //     '세션이 만료되었거나 다른 브라우저에서 로그인 하였습니다. 로그인 화면으로 돌아갑니다.',
      //   );
      //   router.push('/login');
      // }
      return Promise.reject(error);
    },
  );
  return instance;
}

export { setInterceptors, setAuthInterceptors };
