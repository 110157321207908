import store from '@/store/index';

const routes = [
  {
    path: '/market',
    component: () =>
      import(/* webpackChunkName: "market" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'store',
        component: () =>
          import(
            /* webpackChunkName: "market" */ '@/views/market/StorePage.vue'
          ),
        beforeEnter: async (to, from, next) => {
          // beforeEnter 사용 시, 화면이 그려지기 전에 data가 반영돼 그려질때 깔끔함.
          // 입점 현황 조회
          await store.dispatch('market/FETCH_STORE_STATUS').then(() => {
            next();
          });
        },
      },
      {
        path: 'goods',
        component: () =>
          import(
            /* webpackChunkName: "market" */ '@/views/market/GoodsPage.vue'
          ),
        beforeEnter: async (to, from, next) => {
          // 카테고리 목록 조회
          await store.dispatch('market/FETCH_CTGRY_LIST').then(async () => {
            // 컨텐츠 유형 조회
            await store
              .dispatch('common/FETCH_GOOD_CN_TYPE_CODE')
              .then(async () => {
                // 컨텐츠 상위 카테고리 조회
                await store
                  .dispatch('market/FETCH_UPPER_GOODS_CTGRY_CODE')
                  .then(async () => {
                    // 컨텐츠 현황 조회
                    await store
                      .dispatch('market/FETCH_GOODS_STATUS')
                      .then(() => {
                        next();
                      });
                  });
              });
          });
        },
      },
      {
        path: 'approve',
        component: () =>
          import(
            /* webpackChunkName: "market" */ '@/views/market/ApprovePage.vue'
          ),
        beforeEnter: async (to, from, next) => {
          // 월별 입점 승인 현황 조회
          await store.dispatch('market/FETCH_MONTH_APPROVE_STATUS').then(() => {
            next();
          });
        },
      },
      {
        path: 'banner',
        component: () =>
          import(
            /* webpackChunkName: "market" */ '@/views/market/banner/BannerPage.vue'
          ),
        beforeEnter: async (to, from, next) => {
          await store.dispatch('common/FETCH_BANNER_TYPE_CODE').then(() => {
            next();
          });
        },
      },
    ],
  },
];

export default routes;
