const routes = [
  {
    path: '/',
    component: () =>
      import(/* webpackChunkName: "intro" */ '@/layouts/Intro.vue'),
    children: [
      {
        path: 'login',
        meta: { anonymous: true },
        component: () =>
          import(/* webpackChunkName: "intro" */ '@/views/login/LoginPage.vue'),
      },
    ],
  },
];

export default routes;
