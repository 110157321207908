import common from '@/locales/ko/common.json';
import dashboard from '@/locales/ko/dashboard.json';
import service from '@/locales/ko/service.json';
import support from '@/locales/ko/support.json';
import phr from '@/locales/ko/phr.json';
import operation from '@/locales/ko/operation.json';
import login from '@/locales/ko/login.json';

const ko = Object.assign({
  common,
  dashboard,
  service,
  support,
  phr,
  operation,
  login,
});

export default ko;
