const routes = [
  {
    path: '/operation',
    component: () =>
      import(/* webpackChunkName: "operation" */ '@/layouts/Index.vue'),
    children: [
      {
        path: 'operator',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/operator/OperatorPage.vue'
          ),
      },
      {
        path: 'group',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/group/OperatorGroupPage.vue'
          ),
      },
      {
        path: 'qna',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/QnaPage.vue'
          ),
      },
      {
        path: 'notice',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/NoticePage.vue'
          ),
      },
      {
        path: 'transactionlog',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/TransactionlogPage.vue'
          ),
      },
      {
        path: 'system',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/SystemPage.vue'
          ),
      },
      {
        path: 'exhibition',
        component: () =>
          import(
            /* webpackChunkName: "operation" */ '@/views/operation/ExhibitionPage.vue'
          ),
      },
    ],
  },
];

export default routes;
