import axios from 'axios';
import { login, loginWithAuth, auth } from './index';

/**
 * 중복 로그인 체크
 * @returns
 */
function duplicateLoginChk(userId) {
  return login.get(`/duplication/${userId}`);
}

/**
 * 로그인 기록 삭제
 * @returns
 */
function deleteLoginStatus({ userId, password }) {
  return login.post(`/user/${userId}`, { userId, password });
}

/**
 * 로그인
 * @returns
 */
function loginProcess({ userId, password }) {
  return login.post(`/login`, { userId, password });
}

/**
 * 비밀번호 변경
 * @returns
 */
async function changePassword(pwdType, token, payload) {
  try {
    const settingHeaders = {
      headers: {
        'Content-Type': 'application/json',
        Authorization: `Bearer ${token}`,
      },
    };
    return await axios.post(
      `${process.env.VUE_APP_URL_MAS_API}/login/change/${pwdType}`,
      payload,
      settingHeaders,
    );
  } catch (error) {
    return error.response; // error msg 출력 위해 return
  }
}

/**
 * 메뉴 목록 조회
 * @returns
 */
async function getMenuList() {
  return auth.get('/menu');
}

/**
 * 세션 확인
 * @returns
 */
async function sessionCheck() {
  return loginWithAuth.get('token');
}

/**
 * 로그아웃
 * @returns
 */
async function logout() {
  return loginWithAuth.get('logout');
}

export {
  duplicateLoginChk,
  deleteLoginStatus,
  loginProcess,
  changePassword,
  getMenuList,
  sessionCheck,
  logout,
};
