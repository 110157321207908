import { setLineCharData } from '@/api/dashboardApi';
import Highcharts from 'highcharts';
import i18n from '@/i18n/index';
import store from '@/store/index';

//실시간 차트 환경정보
let splineSeries;
let auto_reload_func = null;
/**
 * 플랫폰 이용현황
 * 실시간 차트 데이터 조회
 * @returns
 */
async function setChart1Data() {
  // 로그아웃 버튼 클릭시에도 실시간 API를 계속해서 호출하여 401 에러 발생이 반복되는 현상 방지를 위해 세션 체크 후 API 호출
  if (!store.getters['auth/getLoginStatus']) {
    return false;
  }

  await setLineCharData().then(res => {
    const result = res.data;
    var x = new Date().getTime();
    if (result[0].name === 'Openapi') {
      splineSeries[0].addPoint([x, result[0].y], true, true);
      splineSeries[1].addPoint([x, result[1].y], true, true);
    } else {
      splineSeries[0].addPoint([x, result[1].y], true, true);
      splineSeries[1].addPoint([x, result[0].y], true, true);
    }
  });
}
const state = {
  /**
   *  플랫폼 이용 현황 차트 옵션
   */
  chart1Options: {
    chart: {
      renderTo: 'container',
      type: 'area',
      height: '340',
      animation: Highcharts.SVGElement, // don't animate in old IE
      events: {
        load: function() {
          splineSeries = this.series;
          auto_reload_func = setInterval(() => {
            setChart1Data();
          }, 5000);
        },
        redraw: function() {
          if (!this.hasData()) {
            this.hideNoData();
            this.showNoData(i18n.t('dashboard.chart.noData'));
          }
        },
      },
    },
    title: {
      text: '',
    },
    //하단 하이차트 url 제거
    credits: {
      enabled: false,
    },
    //단추 제거
    exporting: { enabled: false },
    tooltip: {
      split: true,
      valueSuffix: i18n.t('dashboard.chart1.tooltip-valueSuffix'),
      headerFormat: i18n.t('dashboard.chart1.tooltip-headerFormat'),
    },
    time: {
      useUTC: false,
    },
    xAxis: {
      type: 'datetime',
      gridLineWidth: 1,
      labels: {
        rotation: 0,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif',
        },
      },
    },
    yAxis: {
      title: {
        text: i18n.t('dashboard.chart1.yAxis-title'),
      },
      plotLines: [
        {
          value: 0,
          width: 1,
          color: '#808080',
        },
      ],
      minRange: 1,
      min: 0,
    },
    plotOptions: {
      area: {
        //stacking: 'normal',
        lineColor: '#666666',
        lineWidth: 1,
        marker: {
          //lineWidth: 1,
          lineColor: '#666666',
        },
      },
    },
    marker: {
      enable: false,
    },
    series: [
      {
        name: 'Openapi',
        showInLegend: true,
        marker: {
          enabled: false,
        },
        data: (function() {
          var data = [],
            time = new Date().getTime();
          for (var i = -19; i <= 0; i += 1) {
            data.push({
              x: time + i * 5000,
              y: 0,
            });
          }
          return data;
        })(),
      },
      {
        name: 'Fhir',
        showInLegend: true,
        marker: {
          enabled: false,
        },
        data: (function() {
          var data = [],
            time = new Date().getTime();
          for (var i = -19; i <= 0; i += 1) {
            data.push({
              x: time + i * 5000,
              y: 0,
            });
          }
          return data;
        })(),
      },
    ],
  },
  /**
   * 서비스 별 패킷 전송 건 수 차트 옵션
   */
  chart2Options: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      marginBottom: 120,
      marginTop: 40,
      width: '460',
      height: '400',
      type: 'pie',
      events: {
        redraw: function() {
          if (!this.hasData()) {
            this.hideNoData();
            this.showNoData(i18n.t('dashboard.chart.noData'));
          }
        },
      },
    },
    // colors: Highcharts.map(["#9FA8DA","#CCFF99","#FFAB91","#CCCCCC"], function (color) {
    // 	return {
    // 		radialGradient: {cx: 0.5,cy: 0.3,r: 0.7},
    // 		stops: [
    // 			[0, color],
    // 			[1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
    // 		]
    // 	};
    // }),
    title: {
      text: '',
    },
    //하단 하이차트 url 제거
    credits: {
      enabled: false,
    },
    //단추 제거
    exporting: { enabled: false },
    tooltip: {
      pointFormat: i18n.t('dashboard.chart2.tooltip-pointFormat'),
    },
    plotOptions: {
      series: {
        animation: { duration: 1000, easing: 'easeOutBounce' },
        dataLabels: {
          enabled: true,
          style: { fontWeight: 'bold', textOutline: '1', fontSize: '13' },
        },
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: 160,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          //distance: -50, //텍스트 위치 조정
          filter: { property: 'percentage', operator: '>', value: 4 },
        },
      },
    },
    series: [
      {
        data: [],
      },
    ],
  },
  /**
   * PHR 정보 수집 현황 차트 옵션
   */
  chart3Options: {
    chart: {
      type: 'column',
      options3d: {
        enabled: true,
        alpha: 10,
        beta: 25,
        depth: 70,
      },
      marginBottom: 130,
      marginTop: 60,
      marginRight: 30,
      marginLeft: 80,
      events: {
        redraw: function() {
          if (!this.hasData()) {
            this.hideNoData();
            this.showNoData(i18n.t('dashboard.chart.noData'));
          }
        },
      },
    },
    title: {
      text: '',
    },
    xAxis: {
      type: 'category',
      labels: {
        //rotation: -30,
        style: {
          fontSize: '13px',
          fontFamily: 'Verdana, sans-serif',
        },
      },
    },
    yAxis: {
      min: 0,
      title: {
        text: i18n.t('dashboard.chart3.yAxis-title'),
      },
    },
    //하단 하이차트 url 제거
    credits: {
      enabled: false,
    },
    //단추 제거
    exporting: { enabled: false },
    legend: {
      enabled: false,
    },
    tooltip: {
      pointFormat: i18n.t('dashboard.chart3.tooltip-pointFormat'),
    },
    subtitle: {
      text: i18n.t('dashboard.chart3.subtitle'),
    },
    plotOptions: {
      bar: {
        dataLabels: {
          enabled: true,
        },
      },
      series: {
        colorByPoint: true,
      },
    },
    colors: ['#E94CA9', '#FF9748', '#77C159', '#38A1D1', '#C190E2', '#AAAAAA'],
    series: [
      {
        name: i18n.t('dashboard.chart3.series-name'),
        data: [],
        dataLabels: {
          enabled: true,
          color: '#FFFFFF',
          align: 'center',
          y: 30, // 10 pixels down from the top
          style: {
            fontSize: '13px',
            fontFamily: 'Verdana, sans-serif',
          },
        },
      },
    ],
  },
  /**
   * 서비스 별 가입자수 차트 옵션
   */
  chart4Options: {
    chart: {
      plotBackgroundColor: null,
      plotBorderWidth: null,
      plotShadow: false,
      marginBottom: 120,
      marginTop: 40,
      width: '460',
      height: '400',
      type: 'pie',
      events: {
        redraw: function() {
          if (!this.hasData()) {
            this.hideNoData();
            this.showNoData(i18n.t('dashboard.chart.noData'));
          }
        },
      },
    },
    // colors: Highcharts.map(["#9FA8DA","#FFF59D","#FFAB91","#CCCCCC"], function (color) {
    // 	return {
    // 		radialGradient: {cx: 0.5, cy: 0.3, r: 0.7},
    // 		stops: [
    // 			[0, color],
    // 			[1, Highcharts.Color(color).brighten(-0.3).get('rgb')] // darken
    // 		]
    // 	};
    // }),
    title: {
      text: '',
    },
    //하단 하이차트 url 제거
    credits: {
      enabled: false,
    },
    //단추 제거
    exporting: { enabled: false },
    tooltip: {
      pointFormat: i18n.t('dashboard.chart4.tooltip-pointFormat'),
    },
    plotOptions: {
      series: {
        animation: { duration: 1000, easing: 'easeOutBounce' },
        dataLabels: {
          enabled: true,
          style: { fontWeight: 'bold', textOutline: '1', fontSize: '13' },
        },
      },
      pie: {
        allowPointSelect: true,
        cursor: 'pointer',
        size: 160,
        dataLabels: {
          enabled: true,
          format: '<b>{point.name}</b>: {point.percentage:.1f} %',
          //distance: -50, //텍스트 위치 조정
          filter: { property: 'percentage', operator: '>', value: 4 },
        },
      },
    },
    series: [
      {
        data: [],
      },
    ],
  },
};

const getters = {
  getChart1Options: state => state.chart1Options,
  getChart2Options: state => state.chart2Options,
  getChart3Options: state => state.chart3Options,
  getChart4Options: state => state.chart4Options,
};

const mutations = {
  setChart1Options(state, data) {
    state.chart1Options.series[0].data = data;
    state.chart1Options.series[1].data = data;
  },
  setChart1OptionsLocale(state) {
    state.chart1Options.tooltip.valueSuffix = i18n.t(
      'dashboard.chart1.tooltip-valueSuffix',
    );
    state.chart1Options.tooltip.headerFormat = i18n.t(
      'dashboard.chart1.tooltip-headerFormat',
    );
    state.chart1Options.yAxis.title.text = i18n.t(
      'dashboard.chart1.yAxis-title',
    );
  },
  setStopChart1Options(state, data) {
    if (data === 'stop') {
      clearInterval(auto_reload_func);
    }
  },
  setChart2Options(state, data) {
    state.chart2Options.series[0].data = data;
  },
  setChart2OptionsLocale(state) {
    state.chart2Options.tooltip.pointFormat = i18n.t(
      'dashboard.chart2.tooltip-pointFormat',
    );
  },
  setChart3Options(state, data) {
    state.chart3Options.series[0].data = data;
  },
  setChart3OptionsLocale(state) {
    state.chart3Options.series[0].name = i18n.t('dashboard.chart3.series-name');
    state.chart3Options.subtitle.text = i18n.t('dashboard.chart3.subtitle');
    state.chart3Options.tooltip.pointFormat = i18n.t(
      'dashboard.chart3.tooltip-pointFormat',
    );
    state.chart3Options.yAxis.title.text = i18n.t(
      'dashboard.chart3.yAxis-title',
    );
  },
  setChart4Options(state, data) {
    state.chart4Options.series[0].data = data;
  },
  setChart4OptionsLocale(state) {
    state.chart4Options.tooltip.pointFormat = i18n.t(
      'dashboard.chart4.tooltip-pointFormat',
    );
  },
};

const actions = {};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
