const state = {
  ip: '',
};
const getters = {
  getIp: state => state.ip,
};
const mutations = {
  setIp(state, data) {
    state.ip = data;
  },
};
const actions = {};
export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
