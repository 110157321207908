import { phr } from './index';
import axios from 'axios';
import store from '@/store/index';

import { setAuthInterceptors } from './common/interceptors';

/**
 * Patient 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getPatientList(reqObj) {
  return phr.post('/fhir/list', reqObj);
}

/**
 * Patient 상세정보 조회
 * @param {*} reqObj
 * @returns
 */
function getPatientByHistory(reqObj) {
  return phr.post('/fhir/detail', reqObj);
}
/**
 * Patient Resource 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getPatientRsrcList(reqObj) {
  return phr.post('/fhir/resource/list', reqObj);
}
/**
 * Resource 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getResourceList(reqObj) {
  return phr.post('/resource/list', reqObj);
}
/**
 * Resource 상세정보 조회
 * @param {*} reqObj
 * @returns
 */
function getDetailByHistory(reqObj) {
  return phr.post('/resource/detail', reqObj);
}

// ----------------- 비식별화 ----------------- //

/**
 * 사용자 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getUserList(reqObj) {
  return phr.post('/annymty/user', reqObj);
}

/**
 * 사용자 측정내역 목록 조회
 * @param {*} reqObj
 * @returns
 */
function getMesureList(reqObj) {
  return phr.post('/annymty/mesure', reqObj);
}

/**
 * 진행 중인 비식별화 확인
 */
function checkProgressThread() {
  return phr.get('/annymty/thread');
}

/**
 * 비식별화 상세 조회
 * @param {*} type
 * @returns
 */
function getThread(type) {
  return phr.get(`/annymty/thread/${type}`);
}

// axios cacelTloken
let cancelTokenSource_user = null;
let cancelTokenSource_mesure = null;

/**
 * 사용자 비식별화
 * @param {*} payload
 * @returns
 */
function anonymizeUser(payload) {
  cancelTokenSource_user = axios.CancelToken.source();
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL_MAS_API}/phr`,
    cancelToken: cancelTokenSource_user.token,
  });
  const phrWithCancelToken = setAuthInterceptors(instance);
  return phrWithCancelToken.post('/annymty/anonymize/user', payload);
}

/**
 * 사용자 측정내역 비식별화
 * @param {*} payload
 * @returns
 */
function anonymizeMesure(payload) {
  cancelTokenSource_mesure = axios.CancelToken.source();
  const instance = axios.create({
    baseURL: `${process.env.VUE_APP_URL_MAS_API}/phr`,
    cancelToken: cancelTokenSource_mesure.token,
  });
  const phrWithCancelToken = setAuthInterceptors(instance);
  return phrWithCancelToken.post('/annymty/anonymize/mesure', payload);
}

/**
 * thread 취소
 * @param {*} type
 * @param {*} fileNm
 * @returns
 */
function stopThread(type, fileNm) {
  // ajax cancel
  if (
    type == store.state.phr.annymty.PROP_USER_ANNYMTY &&
    cancelTokenSource_user
  ) {
    type == store.state.phr.annymty.PROP_USER_MESURE &&
      cancelTokenSource_user.cancel('비식별화가 취소되었습니다.');
  } else if (cancelTokenSource_mesure) {
    cancelTokenSource_mesure.cancel('비식별화가 취소되었습니다.');
  }
  return phr.delete(`/annymty/thread?type=${type}&fileNm=${fileNm}`);
}

/**
 * CSV 읽기
 * @param {*} filePath
 * @returns
 */
function getCsvFile(filePath) {
  return phr.get(`/annymty/csv?filePath=${filePath}`);
}
export {
  getPatientList,
  getResourceList,
  getPatientByHistory,
  getPatientRsrcList,
  getDetailByHistory,
  getUserList,
  getMesureList,
  checkProgressThread,
  getThread,
  anonymizeUser,
  anonymizeMesure,
  stopThread,
  getCsvFile,
};
